import React from 'react';
import Link from '../elements/link';
import { log as l, stripUrlParkPrefix, lowercaseFirstCharacter } from '../../functions/common.js';
import _get from 'lodash/get';
import _has from 'lodash/has';

// This contains the JSX shell and common logic for producing the Information Box
// paragraph types. It is never loaded directly in a page, but instead is pulled
// into respective Infobox - TYPE components.

export const InfoboxColumn = (props) => {
  // Only render the column if there is at least one visible child at render time
  const hasVisibleChildren = (children) => {
      const childrenArray = React.Children.toArray(props.children);
      return childrenArray.some((child) => child.hasOwnProperty('props') && !child.props.isHidden);
    },
    hasAtLeastOneVisibleChild = hasVisibleChildren(props.children);

  const classNames = props.className ? props.className : 'info-pullout__column clearfix';

  return hasAtLeastOneVisibleChild ? <div className={classNames}>{props.children}</div> : null;
};

export const InfoboxSection = (props) => {
  return (
    <>
      <div className="info-pullout__section">
        <div className="info-pullout__heading">
          <h3>{props.sectionTitle}</h3>
        </div>
        {/* Switch between a HTML list and a div, depending on if the query returns an array */}
        {typeof props.children === 'object' &&
        props.children.length !== undefined &&
        props.children.length > 0 ? (
          <ul className="info-pullout__feature-list">{props.children}</ul>
        ) : (
          <div className="info-pullout__text">{props.children}</div>
        )}
      </div>
    </>
  );
};

/*
 * Generate simple HTML lists from arrays of (objects or strings)
 *    @param data: Array of objects or strings, expects
 *      item.path.alias or item.uri structure
 *    @param titleField: String, the field name of the title in the object
 *    @param boolLink: Boolean, if the list items should be links
 *    @returns: Array of JSX elements
 */
export const list = (data, titleField, boolLink) => {
  if (!data) {
    console.warn('[ISSUE - Scripts]: No data provided to list()');
    return;
  } else if (typeof data !== 'object' && data.length < 1) {
    console.warn(
      '[ISSUE - Scripts]: Data provided to list() must be an array with at least one item'
    );
    return;
  }

  const items = data.map((item, index: Number) => {
    let title;

    // Test if the title field is a string or an object
    // If a string, assume the item itself is the title
    titleField && _has(item, titleField) ? (title = _get(item, titleField)) : (title = item);
    const url = item.path?.alias || item.uri;

    return boolLink === true && url ? (
      <li className="pb-xsmall" key={`link-${title}-${index}`}>
        <Link to={stripUrlParkPrefix(url)}>{title}</Link>
      </li>
    ) : (
      <li key={`link-${title}-${index}`}>{title}</li>
    );
  });
  return items;
};

/*
 * Generate HTML lists with icons, from arrays of objects.
 * (For a list of links or text without icons, use link() instead)
 *    @param data: Array of objects or strings
 *    @param titleField: String - null, the field name of the title in the object
 *    @param statusField: String - null, the field name of the status in the object
 *    @param prefixNos: Boolean - false, if 'No' should be prefixed to the list item
 *    @returns: Array of JSX elements
 */
export const featureList = (
  data,
  titleField: string | null,
  statusField: string | null,
  prefixNos: boolean | false
) => {
  try {
    if (typeof data === 'string') {
      // Allow for a single string to be passed in, rather than an array Convert
      // to an array containing that one item if yes.
      data = [data];
    }

    const items = data.map((item, index: Number) => {
      let itemTitle,
        // Assume the item is enabled by default
        isEnabled = true;

      // Capture all likely options for an 'enabled' state, as we don't know the
      // values being fed into the function. We assume anything that is not a
      // 'yes' option is a No
      const yesOptions = ['yes', 1, 'on', 'enabled', true];

      // Test if the title field is a string or an object
      // If a string, assume the item itself is the title
      typeof titleField === 'string' && _has(item, titleField)
        ? (itemTitle = _get(item, titleField))
        : (itemTitle = item);

      // Test if the enabled field exists in the object
      if (statusField && _has(item, statusField)) {
        isEnabled = _get(item, statusField);
      }
      if (!yesOptions.includes(isEnabled)) {
        isEnabled = false;
      }

      const setIconText = () => {
        return isEnabled ? 'tick' : 'cross';
      };

      // Ensure the nominated title fields doesn't have any child objects before
      // returning
      return typeof itemTitle !== 'object' ? (
        <li
          className={`pb-xsmall info-pullout__feature-list-item info-pullout__feature-list-item--${setIconText()}`}
          key={`${itemTitle}-${index}`}
        >
          <span className="hide-text">{setIconText()} icon</span>
          {prefixNos === true && !isEnabled
            ? `No ${lowercaseFirstCharacter(itemTitle)}`
            : itemTitle}
        </li>
      ) : (
        <React.Fragment key={`${itemTitle}-${index}`}></React.Fragment>
      );
    });
    return items;
  } catch (e) {
    l(e);
  }
};

export const getLocationStatus = (locationStatus: boolean, karStatus: string) => {
  karStatus ? karStatus : null;
  // This assumes all locations are 'open' unless specified elsewhere
  locationStatus ? locationStatus : true;
  let thisStatus =
    locationStatus !== false
      ? karStatus === 'closed'
        ? 'closed'
        : karStatus === 'open_caution'
        ? 'open, but beware of risks'
        : 'open'
      : 'closed';
  return thisStatus;
};

export const InformationBox = (props) => {
  const InfoboxWrapper = (props) => {
    return (
      <div className="pb-xlarge">
        <div className="info-pullout-wrapper" data-view-info-pullout="">
          <div className="grid-wrapper">
            <div className="grid-row clearfix">
              <div className="grid-col grid-col--12 grid-col--no-gutter">
                <div className="info-pullout">
                  <button className="info-pullout__info-tab">
                    <span className="info-pullout__info-tab-inner">
                      <span aria-hidden="true">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path d="M14 0c2.528 0 4.866.627 7.015 1.881a13.966 13.966 0 0 1 5.104 5.104C27.373 9.134 28 11.472 28 14c0 2.528-.627 4.866-1.881 7.015a13.966 13.966 0 0 1-5.104 5.104C18.866 27.373 16.528 28 14 28c-2.528 0-4.866-.627-7.015-1.881a13.966 13.966 0 0 1-5.104-5.104C.627 18.866 0 16.528 0 14c0-2.528.627-4.866 1.881-7.015a13.966 13.966 0 0 1 5.104-5.104C9.134.627 11.472 0 14 0zm0 8.662c.564 0 1.06-.209 1.488-.627.428-.418.642-.909.642-1.473 0-.564-.214-1.065-.642-1.502-.428-.438-.924-.656-1.488-.656s-1.06.219-1.488.656c-.428.438-.642.938-.642 1.502 0 .564.214 1.055.642 1.473.428.418.924.627 1.488.627zm-2.042 13.737h4.083a.909.909 0 0 0 .671-.262.91.91 0 0 0 .263-.671v-.933a.906.906 0 0 0-.263-.671.909.909 0 0 0-.671-.262l.029-7.467a.894.894 0 0 0-.277-.656.895.895 0 0 0-.656-.277h-3.179a.909.909 0 0 0-.671.262.91.91 0 0 0-.263.671v.933c0 .272.087.496.263.671.178.176.42.27.671.262v5.6a.909.909 0 0 0-.671.262.91.91 0 0 0-.263.671v.933c0 .272.087.496.263.671.178.176.42.27.671.262v.001z"></path>
                          </g>
                        </svg>
                      </span>
                      <em>Information</em>
                    </span>
                  </button>
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <InfoboxWrapper props>{props.children}</InfoboxWrapper>;
};
